import React, { useState } from 'react';
import { Dropdown, DropdownButton, ButtonGroup, Button, Container, Row, Col, Badge, Form } from 'react-bootstrap';
import './FilterBar.css';
import { IoMdClose } from "react-icons/io";

const FilterBar = ({ totalCount, onFilterChange }) => {
    const [filters, setFilters] = useState({
        materials: [],
        colors: [],
        price: '',
        availability: '',
        department: '',
        height: '',
        width: '',
        depth: '',
        diameter: '',
        productType: ''
    });

    const [sortBy, setSortBy] = useState('Name A-Z');

    const handleMultiSelectChange = (filterName, value) => {
        const currentSelections = filters[filterName];
        const index = currentSelections.indexOf(value);
        let updatedSelections;
        if (index > -1) {
            updatedSelections = currentSelections.filter(item => item !== value);
        } else {
            updatedSelections = [...currentSelections, value];
        }
        const updatedFilters = { ...filters, [filterName]: updatedSelections };
        setFilters(updatedFilters);
        onFilterChange(updatedFilters, sortBy);
    };

    const handleSortChange = (sortValue) => {
        setSortBy(sortValue);
        onFilterChange(filters, sortValue);
    };

    const clearAllFilters = () => {
        const clearedFilters = {
            materials: [],
            colors: [],
            price: '',
            availability: '',
            department: '',
            height: '',
            width: '',
            depth: '',
            diameter: '',
            productType: ''
        };
        setFilters(clearedFilters);
        onFilterChange(clearedFilters, sortBy);
    };

    // Utility function to render color dropdown items with colored circles
    const renderColorDropdownItem = (colorName, colorValue) => (
        <Dropdown.Item as="button" onClick={() => handleMultiSelectChange('colors', colorValue)} className="d-flex align-items-center">
            <span
                className="color-circle"
                style={{ backgroundColor: colorValue, border: colorValue === 'white' ? '1px solid #ccc' : 'none' }}
            />
            <Form.Check
                type="checkbox"
                label={colorName.charAt(0).toUpperCase() + colorName.slice(1)}
                checked={filters.colors.includes(colorValue)}
                onChange={() => handleMultiSelectChange('colors', colorValue)}
                className="ml-2"
            />
        </Dropdown.Item>
    );

    // Utility function to render material dropdown items
    const renderMaterialDropdownItem = (materialName) => (
        <Dropdown.Item as="button" onClick={() => handleMultiSelectChange('materials', materialName)}>
            <Form.Check
                type="checkbox"
                label={materialName.charAt(0).toUpperCase() + materialName.slice(1)}
                checked={filters.materials.includes(materialName)}
                onChange={() => handleMultiSelectChange('materials', materialName)}
            />
        </Dropdown.Item>
    );

    return (
        <Container fluid>
            <Row className="filter-options">
                <Col md={1}></Col>
                <Col md={8} className="d-inline-flex align-items-center">
                    <DropdownButton as={ButtonGroup} title="Material" id="filter-material">
                        {renderMaterialDropdownItem('glass')}
                        {renderMaterialDropdownItem('leather')}
                        {renderMaterialDropdownItem('metal')}
                        {renderMaterialDropdownItem('stone')}
                        {renderMaterialDropdownItem('upholstered')}
                        {renderMaterialDropdownItem('wood')}
                    </DropdownButton>
                    <DropdownButton as={ButtonGroup} title="Color" id="filter-color">
                        {renderColorDropdownItem('black', 'black')}
                        {renderColorDropdownItem('gray', 'gray')}
                        {renderColorDropdownItem('blue', 'blue')}
                        {renderColorDropdownItem('brown', 'brown')}
                        {renderColorDropdownItem('green', 'green')}
                        {renderColorDropdownItem('white', 'white')}
                        {/* Add more colors as needed */}
                    </DropdownButton>
                </Col>
                <Col md={2}>
                    <span className="pieces-label">
                        <label className="count">{totalCount}</label>
                        <label className="pieces">&nbsp;pieces</label>
                    </span>
                    <label className="sort-by-label">Sort By</label>
                    <DropdownButton as={ButtonGroup} title={sortBy} id="filter-sort-by">
                        <Dropdown.Item onClick={() => handleSortChange('Name A-Z')}>Name A-Z</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSortChange('Name Z-A')}>Name Z-A</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSortChange('Price Low-High')}>Price Low-High</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleSortChange('Price High-Low')}>Price High-Low</Dropdown.Item>
                    </DropdownButton>
                </Col>
                <Col md={1}></Col>
            </Row>
            <Row className="selected-filters">
                <Col md={1}></Col>
                <Col md={10} className="d-inline-flex p-2 align-items-center">
                    {Object.keys(filters).map(key =>
                            filters[key].length > 0 && filters[key].map((value, idx) => (
                                <Badge key={`${key}-${idx}`} className="filter-badge" pill={true}>
                                    {`${key.slice(0, -1).charAt(0).toUpperCase() + key.slice(0, -1).slice(1)}: ${value}`}
                                    <IoMdClose className="btn-close-filters" onClick={() => handleMultiSelectChange(key, value)} />
                                </Badge>
                            ))
                    )}
                    <Button variant="link" className="clear-all" onClick={clearAllFilters}>Clear All</Button>
                </Col>
                <Col md={1}></Col>
            </Row>
        </Container>
    );
}

export default FilterBar;
