import React from 'react';
import { Card, Row, Col, Carousel } from 'react-bootstrap';
import './ProductCard.css';
import { FaExternalLinkAlt, FaStar } from 'react-icons/fa';

// Default image to be displayed if an image fails to load
const defaultImage = "/default-image.jpeg";

const ProductCard = ({ product }) => {
    // Filter out images that do not exist
    const existingImages = product.images.filter((image) => image.image_exists);

    if (existingImages.length === 0) {
        return null;
    }

    // Generate the stars based on the rating
    const renderStars = (rating) => {
        const stars = [];
        for (let i = 1; i <= 5; i++) {
            stars.push(
                <FaStar key={i} className={i <= rating ? "filled-star" : "empty-star"} />
            );
        }
        return stars;
    };

    return (
        <Card className="product-card">
            <a href={product.attributes['URL produit']} className="img">
                <Carousel className="mb-3">
                    {existingImages.map((image, index) => (
                        <Carousel.Item key={index}>
                            <img
                                className="d-block w-100 carousel-image"
                                src={image.image_download_link}
                                alt={image.image_name}
                                onError={(e) => { e.target.onerror = null; e.target.src = defaultImage; }}
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </a>
            <Card.Body className="product-card">
                <Row>
                    <Col lg={7}>
                        <Card.Title className="title">
                            <a href={product.attributes['URL produit']}>{product.product_name}</a>
                        </Card.Title>
                        <div className="retailer-name">
                            {product.retailer}
                        </div>
                    </Col>
                    <Col lg={4} className="text-end">
                        <div className="price">
                            {product.product_price}{' '}{product.currency_code}
                        </div>
                    </Col>
                    <Col lg={1}>
                        <a className="external-link" href={product.attributes['URL produit']} target="_blank" rel="noopener noreferrer">
                            <FaExternalLinkAlt />
                        </a>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col lg={12}>
                        <div className="reviews">
                            <div className="stars">
                                {renderStars(product.customer_reviews)}
                            </div>
                            <div className="review-count">
                                ({product.review_count} reviews)
                            </div>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ProductCard;
