import React from 'react';
import {Navbar, Form, FormControl, Col, Row, Container, NavDropdown, Nav} from 'react-bootstrap';
import './Header.css';
import { FaRegHeart, FaShoppingCart, FaRegUser } from 'react-icons/fa';

const Header = () => {
    return (
        <Container fluid>
            <Row className="header-top align-content-center justify-content-between">
                <Col md={1}></Col>
                <Col md={2}>
                    <Form inline>
                        <FormControl type="text" placeholder="SEARCH"/>
                    </Form>
                </Col>
                <Col md={4}><Navbar.Brand href="#" className="header-logo">DecorAR</Navbar.Brand></Col>
                <Col md={2}>
                    <span className="header-icon"><FaRegUser/></span>
                    <span className="header-icon"><FaRegHeart/></span>
                    <span className="header-icon"><FaShoppingCart/></span>
                </Col>
                <Col md={1}></Col>
            </Row>
            <Row className="header-bottom">
                <Navbar>
                    <Container className="justify-content-center">
                        <Nav>
                            <Nav.Link href="#home">LIVING</Nav.Link>
                            <Nav.Link href="#features">DINING</Nav.Link>
                            <Nav.Link href="#pricing">BEDROOM</Nav.Link>
                            <Nav.Link href="#home">OUTDOOR</Nav.Link>
                            <Nav.Link href="#features">LIGHTING</Nav.Link>
                            <Nav.Link href="#pricing">DECOR</Nav.Link>
                            <Nav.Link href="#home">RUGS</Nav.Link>
                            <Nav.Link href="#features">OFFICE</Nav.Link>
                            <Nav.Link href="#pricing">SALE</Nav.Link>
                            <Nav.Link href="#pricing">DESIGN</Nav.Link>
                        </Nav>
                    </Container>
                </Navbar>
            </Row>
        </Container>
        /*<header className="header">

            <div className="header-bottom">

            </div>
        </header>*/
    );
}

export default Header;
