import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { BsChatDots, BsFillImageFill, BsFillSendFill } from 'react-icons/bs';
import { FaTimes } from 'react-icons/fa';
import './ChatSidebar.css';
import { sendMessage, uploadImage } from '../../services/api';

const ChatSidebar = () => {
    const [show, setShow] = useState(false);
    const [messages, setMessages] = useState([
        {
            type: 'bot',
            text: "Hey there! I am DecorAR AI Assistant. How can I help you?"
        }
    ]);
    const [image, setImage] = useState(null);
    const [conversationId, setConversationId] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    const handleToggle = () => setShow(!show);

    const extractUrl = (text) => {
        const urlPattern = /(https?:\/\/[^\s\\)]+)/g;
        const url = text.match(urlPattern);
        const messageWithoutUrl = text.replace(urlPattern, '').trim();
        return { messageWithoutUrl, url: url ? url[0] : null };
    };

    const handleSend = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const input = form.elements['message'];

        let newMessage = { type: 'user', text: input.value, conversationId };
        if (image) {
            const uploadResponse = await uploadImage(image, conversationId);
            newMessage.image = uploadResponse.data.download_url;
            newMessage.id = uploadResponse.data.id;
            newMessage.conversationId = uploadResponse.data.conversation_id;
            newMessage.messageId = uploadResponse.data.message_id;
            setImage(null);
            setImagePreview(null);
        }

        if (input.value || newMessage.image) {
            setMessages([...messages, newMessage]);
            input.value = '';

            const botResponse = await sendMessage(newMessage);
            setConversationId(botResponse.data.conversation_id);
            const { messageWithoutUrl, url } = extractUrl(botResponse.data.response);

            setMessages(prevMessages => [...prevMessages,
                { conversationId, type: 'bot', text: messageWithoutUrl, url, messageId: botResponse.data.message_id }
            ]);
        }
    };

    const handleImageChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile && selectedFile.type.startsWith('image/')) {
            setImage(selectedFile);
            setImagePreview(URL.createObjectURL(selectedFile));
        } else {
            alert("Please upload a valid image file (e.g., .jpg, .png, .gif)");
            setImage(null);
            setImagePreview(null);
        }
    };

    return (
        <>
            <Button variant="primary" onClick={handleToggle} className="chat-button">
                <BsChatDots size={24} />
            </Button>

            {show && (
                <div className="chat-dialog">
                    <div className="chat-header">
                        <h5>Chat</h5>
                        <FaTimes onClick={handleToggle} className="close-icon" />
                    </div>
                    <div className="chat-content">
                        {messages.map((msg, index) => (
                            <div key={index} className={`chat-message ${msg.type}`}>
                                {msg.image && (
                                    <img src={msg.image} alt="Decoration" className="chat-image" />
                                )}
                                <p>{msg.text}</p>
                                {msg.url && (
                                    <a href={msg.url} target="_blank" rel="noopener noreferrer">
                                        Show similar products
                                    </a>
                                )}
                            </div>
                        ))}
                    </div>
                    <Form onSubmit={handleSend} className="chat-footer">
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder="Type your message..."
                                name="message"
                            />
                            <InputGroup.Text>
                                <Button variant="outline-secondary" onClick={() => document.getElementById('imageUpload').click()}>
                                    <BsFillImageFill />
                                </Button>
                                <input
                                    type="file"
                                    id="imageUpload"
                                    style={{ display: 'none' }}
                                    onChange={handleImageChange}
                                    accept="image/*"
                                />
                                <Button type="submit" variant="primary">
                                    <BsFillSendFill />
                                </Button>
                            </InputGroup.Text>
                        </InputGroup>
                        {imagePreview && (
                            <div className="image-preview">
                                <img src={imagePreview} alt="Selected Preview" className="preview-image" />
                                <Button variant="danger" onClick={() => { setImage(null); setImagePreview(null); }}>Remove</Button>
                            </div>
                        )}
                    </Form>
                </div>
            )}
        </>
    );
};

export default ChatSidebar;
