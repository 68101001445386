import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './ProductList.css';
import { fetchProducts } from '../../services/api';
import { Header, FilterBar, ProductCard, ChatSidebar } from '../../components';

function ProductList() {
  const params = new URLSearchParams(window.location.search);
  const [filters, setFilters] = useState({
    materials: params.get('materials') || [],
    colors: params.get('colors') || [],
    priceMin: params.get('price_min') || '',
    priceMax: params.get('price_max') || '',
    availability: params.get('availability') || '',
    department: params.get('department') || '',
    height: params.get('height') || '',
    width: params.get('width') || '',
    depth: params.get('depth') || '',
    diameter: params.get('diameter') || '',
    productType: params.get('product_type') || '',
    category: params.get('category') || ''
  });
  const [sortBy, setSortBy] = useState('Name A-Z');
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(16);
  const [totalCount, setTotalCount] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPage(1); // Reset page to 1 whenever filters or sortBy change
  }, [filters, sortBy]);

  useEffect(() => {
    loadProducts();
  }, [filters, sortBy, page]); // Also trigger loadProducts when page changes

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) return;
      if (page < totalPages) {
        setPage(prevPage => prevPage + 1);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, page, totalPages]);

  useEffect(() => {
    if (page > 1) {
      loadMoreProducts();
    }
  }, [page]);

  const loadProducts = async () => {
    setLoading(true);
    try {
      const response = await fetchProducts(page, pageSize, filters, sortBy);

      if (page === 1) {
        setProducts(response.data.results); // Reset products if page is 1
      } else {
        setProducts(prevProducts => [...prevProducts, ...response.data.results]); // Load more products if page > 1
      }

      setTotalPages(Math.ceil(response.data.count / pageSize));
      setTotalCount(response.data.count);
    } catch (error) {
      console.error('Failed to fetch products:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreProducts = async () => {
    setLoading(true);
    try {
      const response = await fetchProducts(page, pageSize, filters, sortBy);
      setTotalCount(response.data.count);
      setProducts(prevProducts => [...prevProducts, ...response.data.results]);
    } catch (error) {
      console.error('Failed to fetch more products:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (updatedFilters, sortValue) => {
    setFilters(updatedFilters);
    setSortBy(sortValue);
    setPage(1); // Reset page when filters change
  };

  return (
      <div className="App">
        <Header />
        <FilterBar totalCount={totalCount} onFilterChange={handleFilterChange} />
        <ChatSidebar />
        <Container fluid className="py-4">
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <Row>
                {products.filter(product => product.images.some(image => image.image_exists)).map(product => (
                    <Col sm={12} md={6} lg={4} xl={4} className="mb-4" key={product.product_id}>
                      <ProductCard product={product} />
                    </Col>
                ))}
              </Row>
              {loading && <div className="d-flex justify-content-center my-4">Loading...</div>}
            </Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </div>
  );
}

export default ProductList;
