import axios from 'axios';

const API_URL = 'https://mydecorar.store/api';
//const API_URL = 'http://localhost:8000/api';

// Map sort options to actual field names
const sortOptionsMap = {
    'Name A-Z': 'product_name',
    'Name Z-A': '-product_name',
    'Price Low-High': 'product_price',
    'Price High-Low': '-product_price'
};

export const fetchProducts = (page, pageSize, filters, sortBy) => {
    const params = {
        page,
        page_size: pageSize,
        colors: filters.colors,
        price_min: filters.priceMin,
        price_max: filters.priceMax,
        materials: filters.materials,
        availability: filters.availability,
        department: filters.department,
        height: filters.height,
        width: filters.width,
        depth: filters.depth,
        diameter: filters.diameter,
        product_type: filters.productType,
        category: filters.category,
        sort_by: sortOptionsMap[sortBy] || 'product_name'
    };
    return axios.get(`${API_URL}/shop/products/`, { params });
};

export const sendMessage = async (message) => {
    return axios.post(`${API_URL}/chat/ai/`,
        { "id": message.id,
            'message_type': 'user',
            'content' : message.text,
            'conversation_id': message.conversationId,
            'message_id': message.messageId });
};

export const uploadImage = async (image, conversationId) => {
    const formData = new FormData();
    formData.append('image', image);

    return axios.post(`${API_URL}/chat/upload-image/images/?conversationId=${conversationId}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};
